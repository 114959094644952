<template>
  <van-form @submit="onSubmit">
    <van-cell-group title="请收到货后再确认收货" inset>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        确认收货
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    // const Cookies = inject('Cookies')
    const state = reactive({
      orderId: useRoute.query.orderId,
      rate: 5
    })
    const onSubmit = (values) => {
      Dialog.confirm({
        title: '提示',
        message: '确定这么做吗？'
      }).then(() => {
        values.orderId = state.orderId
        post('/wechatConfirmReceipt', {
          orderId: state.orderId
        }).then(res => {
          if (res.code === 0) {
            Toast.success()
            useRouter.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      }).catch(() => {})
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
